import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { InvokerService } from "src/app/core/invoker.service";
import { Global } from "./../../core/global.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-list1",
  templateUrl: "./list1.component.html",
  styleUrls: ["./list1.component.css"],
})
export class List1Component implements OnInit {
  @Input() dataList;
  @Input() buttons;
  @Input() countInfo;

  constructor(
    private router: Router,
    private invoker: InvokerService,
    public global: Global,
    public http: HttpClient,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  processButton(name: string, value: number): void {
    if (name == "view") {
      this.router.navigate([]).then((result) => {
        window.open("/company/" + value, "_blank");
      });
    }

    if (name == "remove") {
      this.invoker.entityName = "companies/connections";
      this.invoker.deleteResource(value).subscribe(
        (response) => {
          let title: string;
          let msg: string;

          this.translate
            .get("actions.success_title")
            .subscribe((res: string) => {
              title = res;
            });

          this.translate
            .get("actions.success_msg_remove")
            .subscribe((res: string) => {
              msg = res;
            });

          this.global.toaster.success(msg, title);
        },
        (error: any) => {
          let title: string;
          let msg: string;

          this.translate.get("actions.error_title").subscribe((res: string) => {
            title = res;
          });

          this.translate
            .get("actions.error_msg_remove")
            .subscribe((res: string) => {
              msg = res;
            });

          this.global.toaster.success(msg, title);
        }
      );
    }

    if (name == "withdraw") {
      this.invoker.entityName = "companies/invitations";
      this.invoker.deleteResource(value).subscribe(
        (response) => {
          let title: string;
          let msg: string;

          this.translate
            .get("actions.success_title")
            .subscribe((res: string) => {
              title = res;
            });

          this.translate
            .get("actions.success_msg_withdraw")
            .subscribe((res: string) => {
              msg = res;
            });

          this.global.toaster.success(msg, title);
        },
        (error: any) => {
          let title: string;
          let msg: string;

          this.translate.get("actions.error_title").subscribe((res: string) => {
            title = res;
          });

          this.translate
            .get("actions.error_msg_withdraw")
            .subscribe((res: string) => {
              msg = res;
            });

          this.global.toaster.error(msg, title);
        }
      );
    }

    if (name == "accept" || name == "deny") {
      let statusOficial: string = name == "accept" ? "A" : "D";

      this.invoker.entityName = "companies/invitations/" + value;
      this.invoker
        .updateResource({
          status: statusOficial,
        })
        .subscribe(
          (response: any) => {
            let title: string;
            let msg: string;

            this.translate
              .get("actions.success_title")
              .subscribe((res: string) => {
                title = res;
              });

            this.translate
              .get("actions.success_msg_" + name)
              .subscribe((res: string) => {
                msg = res;
              });

            this.global.toaster.success(msg, title);
          },
          (error: any) => {
            let title: string;
            let msg: string;

            this.translate
              .get("actions.error_title")
              .subscribe((res: string) => {
                title = res;
              });

            this.translate
              .get("actions.error_msg_" + name)
              .subscribe((res: string) => {
                msg = res;
              });

            this.global.toaster.error(msg, title);
          }
        );
    }

    // remove element in all cases, least view button
    if (name != "view") {
      this.dataList = this.dataList.filter((item) => item.id !== value);
    }
  }
}

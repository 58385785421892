import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StripeService, Elements, Element as StripeElement, ElementsOptions, PaymentIntent } from "ngx-stripe";
import { Global } from 'src/app/core/global.service';
import { InvokerService } from 'src/app/core/invoker.service';
import { SubscriptionType } from 'src/app/interfaces/subscription-type';

@Component({
  selector: 'app-subscription-payment',
  templateUrl: './subscription-payment.component.html',
  styleUrls: ['./subscription-payment.component.css']
})
export class SubscriptionPaymentComponent implements OnInit {

  	public promoCodeIsInvalid: boolean = false;
	public loading: boolean = false;
	public stripeElements: Elements;
	public stripeCard: StripeElement;
	public subscriptionType: SubscriptionType;
	public info = this.global.getLoggedUser();
	public entity:any = {};
	public coupon:any = {};

	public stripeElementsOptions: ElementsOptions = {
		locale: 'auto'
	};

	constructor(
    	public global: Global,
		private stripeService: StripeService,    
		private invoker: InvokerService,
		private navigateRouter: Router,
		public translate: TranslateService,
  	) { }

	ngOnInit() {
		localStorage.getItem('planSelected') ? this.subscriptionType = JSON.parse(localStorage.getItem('planSelected')) : this.subscriptionType = null;
		this.createStripeCardElement();
		this.getCustomerStripeId();
	}

	getCustomerStripeId() {
		this.invoker.entityName = 'subscription/companies/customer';
		this.invoker.getResources().subscribe(response => {
		})
	}

	createStripeCardElement(): void {
		this.stripeService.elements(this.stripeElementsOptions).subscribe(elements => {
			this.stripeElements = elements;
			if (!this.stripeCard) {
				this.stripeCard = this.stripeElements.create('card', {
					style: {
						base: {
							iconColor: '#666EE8',
							color: '#31325F',
							lineHeight: '30px',			
							fontFamily: 'inherit',
							fontSize: '16px',
							'::placeholder': {
								color: '#999'
							}
						}
					}
				});
				this.stripeCard.mount('#card-element');
			}
		});
	}

	submitPayment() {

		if (this.entity.cardName == null || this.entity.cardName.trim().length == 0) {
			this.translate.get("subscription.card_name").subscribe((res: string) => {
				this.global.toaster.error(res);
			});
			return;
		}
		this.loading = true;
		this.stripeService.createToken(this.stripeCard, { name: this.entity.cardName }).subscribe(
			(result:any) => {
				this.loading = false;
				if (result.token) {	
					this.associateCardToCustomerStripe(result.token.card.id, result.token.id);					
				}
				else {
					this.loading = false;
				}
			},
			(error:any) => {
				this.loading = false;
			}
		);
	}


	associateCardToCustomerStripe(cardId, tokenId) {

		if (this.subscriptionType.id == null || this.subscriptionType.id == 0) {
			this.translate.get("subscription.card_name").subscribe((res: string) => {
				this.global.toaster.error(res);
			});
			return;
		}
		let body = {
			codeStripeCard: cardId,
			codeStripeTok: tokenId,
			couponDiscount: this.coupon.value,
			subscriptionTypeId: this.subscriptionType.id
		}
		this.loading = true;
		this.invoker.entityName = 'paymentmethod/subscriptionplanstripe';
		this.invoker.createResource(body).subscribe(
			(response:any) => {
				this.translate.get("subscription.payment_made").subscribe((res: string) => {
					this.global.toaster.success(res);
				});
				this.loading = false;
				this.navigateRouter.navigate([`/feed`]);
			},
			(error:any) => {
				this.loading = false;
			}
		);
			
	}

	public selectField(field: any) {
		field.selectionStart = 0;
		field.selectionEnd = field.value.length;
	}

	promoCodeValidate(promoCode: any){

		if(promoCode.value == null || promoCode.value.trim().length == 0){
			this.promoCodeIsInvalid = true;		
			return;
		}

		let body = {
			coupon: promoCode.value,
			subscriptionId: this.subscriptionType.id
		}

		this.promoCodeIsInvalid = false;
		this.coupon.valid = false;
		this.invoker.entityName = 'paymentmethod/validatecoupon';
		this.invoker.createResource(body).subscribe(
			(response:any) => {
				this.coupon = response.coupon;
				this.coupon.valid = true;
				this.coupon.value = promoCode.value;
			},
			(error:any) => {
				this.coupon.valid = false;
			}
		);
	}


}

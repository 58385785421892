import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Global } from 'src/app/core/global.service';

@Component({
  selector: 'app-modal-unauthorized',
  templateUrl: './modal-unauthorized.component.html',
  styleUrls: ['./modal-unauthorized.component.css']
})
export class ModalUnauthorizedComponent implements OnInit {

  public loading: boolean = false;
  public info: any;

  constructor(
    public global: Global,
    public bsModalRef: BsModalService
  ) { }

  ngOnInit(): void {

  }

  refreshPage(): void {
    location.reload();
  }

}

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Customer } from '../interfaces/customer';
import { Global } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardWelcomeService implements CanActivate {

  constructor(
    private router: Router,
    private global: Global
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.verifyLogin();
  }

  private verifyLogin(): boolean {
    if (!this.global.isLogged()) {
      this.router.navigate(['/home']);
      return false;
    } else {
      let customer: Customer = JSON.parse(localStorage.getItem('customer'));

      if (!customer) {
        this.router.navigate(['/home']);
      } else {
        this.router.navigate(['/feed']);
      }    
    }
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "./core/auth-guard.service";
import { TranslateModule } from "@ngx-translate/core";
import { NotFoundComponent } from "./shared-components/not-found/not-found.component";
import { SubscriptionComponent } from "./shared-components/subscriptions/subscription/subscription.component";
import { SubscriptionPaymentComponent } from "./shared-components/subscriptions/subscription-payment/subscription-payment.component";
import { AuthGuardWelcomeService } from "./core/auth-guard-welcome.service";

const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuardWelcomeService],
    loadChildren: () =>
      import("./modules/initial/initial.module").then((m) => m.InitialModule),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./modules/initial/initial.module").then((m) => m.InitialModule),
  },
  {
    path: "account",
    loadChildren: () =>
      import("./modules/account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "feed",
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./modules/feed/feed.module").then((m) => m.FeedModule),
  },
  {
    path: "company",
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./modules/company/company.module").then((m) => m.CompanyModule),
  },
  {
    path: "user",
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./modules/customer/customer.module").then((m) => m.CustomerModule),
  },
  {
    path: "invitation",
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./modules/invitation/invitation.module").then(
        (m) => m.InvitationModule
      ),
  },
  {
    path: "onboarding",
    loadChildren: () =>
      import("./modules/onboarding/onboarding.module").then(
        (m) => m.OnboardingModule
      ),
  },
  {
    path: "search",
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./modules/search/search.module").then(
        (m) => m.SearchModule
      ),
  },
  {
    path: "challenge",
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./modules/challenge/challenge.module").then((m) => m.ChallengeModule),
  },
  {
    path: "incubator",
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./modules/incubator/incubator.module").then((m) => m.IncubatorModule),
  },
  {
    path: "profile",
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./modules/profile/profile.module").then((m) => m.ProfileModule),
  },
  {
    path: "welcome",
    loadChildren: () =>
      import("./modules/waitroom/waitroom.module").then((m) => m.WaitroomModule),
  },
  {
    path: "legal",
    loadChildren: () =>
      import("./modules/legal/legal.module").then((m) => m.LegalModule),
  },
  {
    path: "subscription",
    component: SubscriptionComponent
  },
  {
    path: "subscription-payment",
    component: SubscriptionPaymentComponent
  },
  {
    path: '**', 
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), TranslateModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}

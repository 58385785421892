import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'src/app/core/global.service';
import { InvokerService } from 'src/app/core/invoker.service';
import { Company } from 'src/app/interfaces/company';
import { Customer } from 'src/app/interfaces/customer';

@Component({
  selector: 'app-navbar-incubator',
  templateUrl: './navbar-incubator.component.html',
  styleUrls: ['./navbar-incubator.component.css']
})
export class NavbarIncubatorComponent implements OnInit {

  public user: Customer;
  public company: Company;

  public defaultLanguage;

  constructor(public global: Global, private router: Router, private invoker: InvokerService) {
    this.global.user$.subscribe(user => {
      this.user = user;
    })
    this.global.company$.subscribe(company => {
      this.company = company;
    })
  }

  ngOnInit(): void {
    if (this.user) {

      if (this.user.language.name) {
        this.defaultLanguage = this.user.language.name;
      } else {
        this.defaultLanguage = this.user.language;
      }
    }
  }

  changeDefaultLanguage(language): void {

    let body = {
      email: this.user.email,
      language: language
    };

    this.invoker.entityName = "customers/changelanguage";
    this.invoker.createResource(body).subscribe(
      (response: any) => {

        this.defaultLanguage = language;
        this.user.language = language;

        this.global.setUserData(this.user);
        location.reload();

      },
      (error: any) => { }
    );


  }

  logout(): void {
    this.global.signout();
  }

}
